import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/banner-1.jpg'
import hero2 from '../../images/slider/banner-2.jpg'
import hero3 from '../../images/slider/banner-3.jpg'
import TimeCountDown from "../countdown";

class Hero5 extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: true
    };
    return (
      <section className="wpo-hero-slider wpo-hero-style-3">
        <div className="wedding-announcement">
          <div className="couple-text">
            <h2 className="slideInUp">Clara e Daniel</h2>
            <p className="slideInUp">03/08/2024</p>
            <div className="submit-area">
              <div className="form-submit">
                <a href="https://lista.claraedaniel.love" target="_blank" className="theme-btn-s3">Lista de presentes dos noivos</a>
              </div>
            </div>
            <TimeCountDown/>
          </div>
        </div>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <Slider {...settings}>
              <div className="hero-slide">
                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}></div>
              </div>
              <div className="hero-slide">
                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}></div>
              </div>
              <div className="hero-slide">
                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}></div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    )
  }
}

export default Hero5;
