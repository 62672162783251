import React from 'react';

import Logo from '../../images/black-logo.svg';

const Footer = () =>{
  return(
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="logo"/>
                </div>
                <p>Site feito com amor e carinho</p>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contato </h3>
                </div>
                <div className="contact-ft">
                  <p>Converse com os noivos e sua equipe</p>
                  <ul>
                    <li><i className="fi flaticon-email"></i>contato@claraedaniel.love</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright"> &copy; Clara e Daniel</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
} 

export default Footer;
