import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import HomePage from '../HomePage';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomePage/>} />
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
