import React, { Component } from 'react';

import coupleImg1 from '../../images/couple/2.jpg';
import coupleImg2 from '../../images/couple/1.jpg';
import coupleImg3 from '../../images/couple/shape.png';
import coupleImg4 from '../../images/couple/3.jpg';
 
class CoupleSection extends Component {
  render() {
    return(
      <section className={`couple-section section-padding ${this.props.cClass}`} id="couple">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-xs-12">
                <div className="couple-area clearfix">
                  <div className="text-grid bride">
                    <div className="couple-img">
                      <img src={coupleImg1} alt=""/>
                    </div>
                    <h3>Clara Silberschneider</h3>
                    <p>Linda e Maravilhosa. Defensora dos animais, dos anos 80 e de um bom e velho Rock and roll.</p>
                  </div>
                  <div className="middle-couple-pic">
                    <img src={coupleImg2} alt=""/>
                    <div className="frame-img"><img src={coupleImg3} alt=""/></div>
                  </div>
                  <div className="text-grid groom">
                    <div className="couple-img">
                      <img src={coupleImg4} alt=""/>
                    </div>
                    <h3>Daniel Rodrigues</h3>
                    <p>Simpático e Carinhoso. Defensor do pão com mortadela, do café forte e do Kubernetes.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
export default CoupleSection;
