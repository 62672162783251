import React, { Fragment } from 'react';

import Navbar2 from '../../components/Navbar2';
import Hero5 from '../../components/hero5';
import CoupleSection from '../../components/CoupleSection';
import RSVP from '../../components/RSVP';
import Scrollbar from '../../components/scrollbar'
import Logo from "../../images/white-logo.svg";
import Footer from '../../components/footer';
import QuestionSection from '../../components/QuestionSection';

const HomePage =() => {
    return(
      <Fragment>
        <Navbar2 Logo={Logo} topbarNone={'topbarNone'} hclass={'wpo-header-style-3'}/>
        <Hero5/>
        <CoupleSection cClass={'pt-160'}/>
        <QuestionSection/>
        {/* <RSVP/> */}
        <Footer/>
        <Scrollbar/>
      </Fragment>
    )
};

export default HomePage;
