import React from 'react'
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import "react-buzzfeed-quiz/lib/styles.css";

import FirstResultImage from '../../images/quiz/daniel.jpg';
import SecondResultImage from '../../images/quiz/clara.jpg';
import ThirdResultImage from '../../images/quiz/mira.jpg';
import FourthResultImage from '../../images/quiz/lins.jpg';

const QuestionSection = () => {
  return (
    <section className="story-section section-padding" id="questions">
      <div className="container">
        <BuzzFeedQuiz
          title={"Descubra quem você é da família"}
          description={"Algumas perguntinhas para saber com quem da família dos noivos você mais se parece."}
          byline={true}
          bylineAuthor={"Noivos"}
          bylineAuthorLinkOpenInNewTab={true}
          bylineAuthorTagline={"Sobre o casal"}
          autoScroll={true}
          facebookShareButton={false}
          twitterShareButton={false}
          copyShareButton={false}
          questions={[
            {
              question: "Quando você fica sozinho(a) em casa, você:",
              answers: [{
                answer: "Gosto de abrir e mexer no meu laptop",
                resultID: 0,
              },{
                answer: "Aproveito pra tirar aquele cochilo sem interrupções",
                resultID: 2,
              },{
                answer: "Adoro ver um filme ou cozinhar bolo",
                resultID: 1,
              },{
                answer: "Dou uma esticada de pernas pela casa, andando de cômodo em cômodo",
                resultID: 3,
              }],
            },
            {
            question: "Na hora que a visita chega em casa, você:",
            answers: [{
                answer: "Corro para o quarto",
                backgroundColor: "red",
                fontColor: "rgb(215, 215, 215)",
                resultID: 2,
              },{
                answer: "Já chego perguntando da vida e curiosidades",
                backgroundColor: "orange",
                fontColor: "green",
                resultID: 3,
              },{
                answer: "Fecho a porta do meu quarto",
                backgroundColor: "yellow",
                fontColor: "#000",
                resultID: 0,
              },{
                answer: "Ofereço um café",
                backgroundColor: "red",
                fontColor: "rgb(215, 215, 215)",
                resultID: 1,
              }],
            },
            {
              question: "Qual o seu tipo de filme favorito?",
              answers: [{
                answer: "Curto um suspense ou uma ação",
                resultID: 0,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              },{
                answer: "Não me importo, ligou a TV já me bate aquele sono",
                resultID: 2,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              },{
                answer: "Minha hiperatividade me impede de ficar parada por duas horas na frente da TV",
                resultID: 3,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              },{
                answer: "No dia a dia da vida prefiro uma comédia romântica daquelas cabeça",
                resultID: 1,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              }],
            },
            {
            question: "Qual sua comida favorita?",
            answers: [{
                answer: "Qualquer coisa que estiver na minha frente",
                resultID: 3,
              },{
                answer: "Carne",
                resultID: 0,
              },{
                answer: "Qualquer coisa com camarão",
                resultID: 1,
              },{
                answer: "Prefiro comida sem molho",
                resultID: 2,
              }],
            },
            {
              question: "Qual das frases abaixo melhor representa a sua relação com o sono?",
              answers: [{
                answer: "Sou dessas que sempre levanto da cama pra um lanchinho noturno",
                resultID: 2,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              },{
                answer: "Sou mais do dia, no corre corre gosto de dormir mais cedo",
                resultID: 1,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              },{
                answer: "Deito em qualquer lugar, qualquer hora e durmo profundamente em segundos",
                resultID: 0,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              },{
                answer: "Dormir? As luzes se apagam e essa é minha deixa para curtir",
                resultID: 3,
                backgroundColor: "rgb(238,243,247)",
                fontColor: "rgb(53,51,48)",
              }],
            },
            {
              question: "Qual sua relação com música?",
              backgroundColor: "rgb(211, 211, 211)",
              fontColor: "#000",
              answers: [{
                answer: "Escuto sempre pelo fone de ouvido",
                backgroundColor: "red",
                fontColor: "rgb(215, 215, 215)",
                resultID: 0,
              },{
                answer: "Fico curiosa com qualquer som diferente no pedaço",
                backgroundColor: "orange",
                fontColor: "green",
                resultID: 3,
              },{
                answer: "Prefiro pedir pra Alexa ou ouvir no viva voz",
                backgroundColor: "yellow",
                fontColor: "#000",
                resultID: 1,
              },{
                answer: "Não gosto muito de música alta",
                backgroundColor: "red",
                fontColor: "rgb(215, 215, 215)",
                resultID: 2,
              }],
            },
          ]}
          results={[
            {
              title: "Daniel",
              description: "Mistura de Milenial com Gen Z, você é mais na sua e adora cantarolar suas músicas preferidas escutando com um fone de ouvido (eu ouvi mestrado aí?). Adora aventuras no dia a dia, seja no trabalho ou na vida pessoal, e ama de paixão o que você faz na vida",
              resultImageSrc: FirstResultImage,
              imageAttribution: "",
              resultID: 0,
            },{
              title: "Clara",
              description: "Você é uma pessoa alegre e misteriosa, que adora cozinhar para os amigos e ouvir um bom som. Adora um social, contando que dê pra se planejar antes, né? Curtir um cineminha ou um filme com o mozão estão na sua lista de atividades favoritas para o final de semana. ",
              imageAttribution: "",
              resultImageSrc: SecondResultImage,
              resultID: 1,
            },{
              title: "Mira",
              description: "Você é uma pessoa bem na sua, não gosta muito de interagir socialmente e com certeza adora um longo sono…! Como a Mira, você adora um carinho e sempre quer mais cafuné. É uma pessoa cheia de mistérios e não se abre com qualquer um!",
              imageAttribution: "",
              resultImageSrc: ThirdResultImage,
              resultID: 2,
            },{
              title: "Lins",
              description: "Hiperatividade é o seu nome do meio! Assim como a Lins, você adora uma festa, pessoas e está sempre curiosa(o) para saber quem está chegando na área. Mas claro, tudo do seu jeito. Detesta melosidades e adora carinho na hora que você bem quer.",
              imageAttribution: "",
              resultImageSrc: FourthResultImage,
              resultID: 3,
            }
          ]}
        />
      </div>
    </section>
  )
}

export default QuestionSection;
