import React, { Component } from 'react';
import Swal from 'sweetalert2';

import SectionTitle from '../../components/SectionTitle';
import vec1 from '../../images/contact/1.png';
import vec2 from '../../images/contact/2.png';

class RSVP extends Component {
  state = {
    name: '',
    email: '',
    guest: '',
    guests: '',
    error: {}
  }

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = ''
    
    this.setState({
      [e.target.name]: e.target.value,
      error
    })

    if ((e.target.name === 'guest' && e.target.value === 'Número de pessoas que você vai levar') || 
        (e.target.name === 'guest' && e.target.value === 'Irei sozinho(a)')) {
      error['guests'] = ''
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { name, email, guest, guests, error } = this.state;

    if (name === '') {
      error.name = "Digite o seu nome";
    }
    if (email === '') {
      error.email = "Digite o seu email";
    }
    if (guest === '' || guest === 'Número de acompanhantes') {
      error.guest = "Número de acompanhantes";
    }
    if (guest !== 'Irei sozinho(a)' && guests === '') {
      error.guests = "Nome das pessoas que você vai levar";
    }

    if (error) {
      this.setState({
        error
      })
    }
    if (error.name === '' && error.email === '' && error.guest === '' && error.guests === '') {
      fetch('https://claraedaniel-backend-v2.onrender.com/guests', {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          guestQuantity: guest === 'Irei sozinho(a)' ? 0 : Number(guest),
          guestNames: guests
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((response) => response.json()).then((response) => {
        if (response && response.guestId) {
          Swal.fire({
            icon: "success",
            title: "Eba!",
            html: `
              A sua presença está confirmada, em breve novidades!<br><br>Não deixe de conferir a nossa <b>Lista de 
              Presentes</b> clicando <a href="https://lista.claraedaniel.love" target="_blank">aqui</a>.
            `,
          });
          this.setState({
            name: '',
            email: '',
            guest: '',
            guests: '',
            error: {}
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Houve um erro na confirmação, verifique os dados preenchidos",
          });
        }
      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Houve um erro na confirmação, verifique os dados preenchidos",
        });
      });
    }
  }

  render(){
    const { name, email, guest, guests, error } = this.state;
    return (
      <section className="wpo-contact-section section-padding" id="RSVP">
        <div className="container">
            <div className="wpo-contact-section-wrapper">
              <div className="wpo-contact-form-area">
                <SectionTitle topTitle={'RSVP'} MainTitle={'Confirme a sua presença'} />
                <form onSubmit={this.submitHandler} className="form">
                  <div className="row">
                    <div>
                      <div className="form-field">
                        <input value={name} onChange={this.changeHandler} className="form-control" type="text" name="name" placeholder="Nome"/>
                        <p>{error.name ? error.name : ''}</p>
                      </div>
                    </div>
                    <div>
                      <div className="form-field">
                        <input onChange={this.changeHandler} value={email} type="email" className="form-control" name="email" placeholder="E-mail"/>
                        <p>{error.email ? error.email : ''}</p>
                      </div>
                    </div>
                    <div>
                      <select name="guest" className="form-control" value={guest} onChange={this.changeHandler}>
                        <option>Número de acompanhantes</option>
                        <option>Irei sozinho(a)</option>
                        <option value={1}>01</option>
                        <option value={2}>02</option>
                        <option value={3}>03</option>
                        <option value={4}>04</option>
                        <option value={5}>05</option>
                      </select>
                      <p>{error.guest ? error.guest : ''}</p>
                    </div>
                    <div>
                      <div className="form-field">
                        <textarea name="guests" className="form-control" placeholder="Coloque os nomes separados por vírgula" onChange={this.changeHandler} value={guests}>{guests}</textarea>
                      </div>
                      <p>{error.guests ? error.guests : ''}</p>
                    </div>
                    <div className="submit-area">
                      <div className="form-submit">
                        <button type="submit" className="theme-btn-s3">Confirmar</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="border-style"></div>
              </div>
              <div className="vector-1">
                <img src={vec1} alt=""/>
              </div>
              <div className="vector-2">
                <img src={vec2} alt=""/>
              </div>
            </div>
        </div>
      </section>
    )
  }
}

export default  RSVP;
