import React, { Component } from "react";
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';

import MobileMenu2 from "../MobileMenu2";

class Header2 extends Component {
  render() {
    return (
      <header id="home" className={this.props.topbarNone}>
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu2 />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <NavLink className="navbar-brand" to="/">
                      <img src={this.props.Logo} alt="Clara e Daniel logo" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li className="menu-item-has-children">
                        <Link activeClass="active" to="home" spy={true} smooth={true} duration={500} >Início</Link>
                      </li>
                      <li><Link activeClass="active" to="couple" spy={true} smooth={true} offset={-100}  duration={500} >Sobre os noivos</Link></li>
                      <li><Link activeClass="active" to="questions" spy={true} smooth={true}  duration={500} >Quiz</Link></li>
                      <li><Link activeClass="active" to="RSVP" spy={true} smooth={true}  duration={500} >Confirmar presença</Link></li>
                      <li><a href="https://lista.claraedaniel.love" activeClass="active" target="_blank">Lista de presentes</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header2;
